import { useQuery } from '@apollo/client';
import {
  Button,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableRow as MuiTableRow,
  Typography
} from '@material-ui/core';
import { navigate } from '@reach/router';
import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import { LoadingSpinner } from 'components/LoadingSpinner';
import Search from 'components/Search';
import { useAddSearchTranslationHook } from 'hooks/useAddSearchTranslationHook';
import { useDefaultErrorHandler } from 'hooks/useDefaultErrorHandler';
import { useSorted } from 'hooks/useSorted';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  ExerciseFragment,
  GetAllExercisesDocument,
  GetAllExercisesQuery,
  GetAllExercisesQueryVariables
} from 'types';
import { FlattenedExercise, InterfaceToRecord } from 'types/exerciseRepo';
import { exerciseTableHeadCells, TableCell } from 'utils/libraryTableCells';
import ExerciseThumbnail from '../activity-plan/ExerciseThumbnail';
import SortTableHead from '../SortTableHead';
import RepTypeChip from './RepTypeChip';

type OrderByExercise = 'name' | 'anatomicalName' | 'repType';

const isOrderByExercise = (property: string): property is OrderByExercise => {
  return (
    property === 'name' ||
    property === 'anatomicalName' ||
    property === 'repType'
  );
};

const TableWrapper = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(0, 15, 0, 3)};
  `}
`;

const SearchWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(0, 0, 6)};
`;

const Divider = styled(MuiDivider)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const TableRow = styled(MuiTableRow)`
  height: 52px;
  &:hover {
    background-color: #fafafa;
  }
`;

const NoExercisesWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(4)};
`;

const ActionCell = styled(TableCell)`
  width: 5%;
  padding-left: 0;
`;

const NameFlex = styled.div`
  display: flex;
  width: 100%;
  gap: 4px;
  align-items: center;
`;

export const ExercisesTable = (): JSX.Element => {
  const { t: translateContent } = useTranslation();
  const { defaultErrorHandler } = useDefaultErrorHandler();
  const { order, orderBy, toggle, setItems, items } = useSorted<
    OrderByExercise,
    InterfaceToRecord<FlattenedExercise>
  >({
    initialOrderBy: 'name'
  });

  const { data, loading, error } = useQuery<
    GetAllExercisesQuery,
    GetAllExercisesQueryVariables
  >(GetAllExercisesDocument, { onError: defaultErrorHandler });

  useEffect(() => {
    if (error) {
      snackbarState({
        type: 'generic',
        severity: 'error',
        title: 'Unable to load exercises',
        message: 'Try again'
      });
      return;
    }
  }, [error]);
  const {
    exerciseSearchTerm: exerciseSearch,
    setExerciseSearchTerm: setExerciseSearch
  } = useAddSearchTranslationHook(data, setItems);

  const handleExerciseSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setExerciseSearch(event.target.value.toLowerCase());
  };

  const handleRequestSort = (
    event: React.MouseEvent,
    property: string
  ): void => {
    if (isOrderByExercise(property)) {
      toggle(property);
    }
  };

  return (
    <div data-testid="exerciseRepo">
      <TableWrapper>
        <SearchWrapper>
          <Search
            autoFocus
            searchTerm={exerciseSearch}
            handleChange={handleExerciseSearchChange}
            name="configsSearch"
            placeholder="Search exercises"
          />
        </SearchWrapper>
        <Divider />
        {items.length > 0 ? (
          <Table aria-label="Exercise library table">
            <SortTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              tableHeadCells={exerciseTableHeadCells}
            />
            <TableBody data-testid="exerciseLibraryBody">
              {items.map(
                ({
                  uuid,
                  thumbnail,
                  name,
                  anatomicalName,
                  repType
                }: Partial<ExerciseFragment>): JSX.Element => (
                  <TableRow key={uuid} data-testid="exerciseLibraryItem">
                    <TableCell data-testid="nameCell">
                      <NameFlex>
                        {thumbnail ? (
                          <ExerciseThumbnail src={thumbnail.url} />
                        ) : null}
                        <Typography>
                          {name && translateContent(name)}
                        </Typography>
                      </NameFlex>
                    </TableCell>
                    <TableCell width={28} data-testid="anatomicalNameCell">
                      <Typography color="textSecondary">
                        {anatomicalName}
                      </Typography>
                    </TableCell>
                    <TableCell width={12} data-testid="repTypeCell">
                      <RepTypeChip repType={repType} />
                    </TableCell>
                    <ActionCell>
                      <Button
                        color="primary"
                        onClick={() => navigate(`/exercises/${uuid}`)}
                        data-testid="viewExerciseButton"
                      >
                        VIEW
                      </Button>
                    </ActionCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        ) : (
          <NoExercisesWrapper>
            <Typography variant="h6" component="h3">
              {loading ? (
                <LoadingSpinner data-testid="loading" aria-label="loading" />
              ) : (
                'No exercises found :('
              )}
            </Typography>
          </NoExercisesWrapper>
        )}
      </TableWrapper>
    </div>
  );
};
