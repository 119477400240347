const genericErrorMesage = (action: string): string =>
  `Unable to ${action} changes`;
export const genericSaveErrorMessage = genericErrorMesage('save');
export const genericCommitErrorMessage = genericErrorMesage('publish');

const ERROR_MESSGAES = {
  RESOURCE_NOT_FOUND:
    'This draft no longer exists. Please discard the given draft and try again.',
  BAD_USER_INPUT:
    'Invalid input in the given draft. Please check the values entered.',
  INTERNAL_SERVER_ERROR:
    'Something went wrong on the server side. Please try again.'
};

const customConflictMessage = (action: string): string =>
  `Conflict occured while ${action} draft.`;

export const formattedSaveErrorResponse = (
  status: boolean,
  error?: string
): { status: boolean; errorMessage?: string } => ({
  status,
  errorMessage: error
});

export const formattedCommitErrorResponse = (
  didCommit: boolean,
  error?: string,
  activityPlanuuid?: string
): {
  didCommit: boolean;
  errorMessage?: string;
  activityPlanuuid?: string;
} => ({
  didCommit,
  activityPlanuuid,
  errorMessage: error
});

const handleCustomErrors = (httpStatusCode: string, action: string): string => {
  let msg: string;
  switch (httpStatusCode) {
    case '409':
      msg = customConflictMessage(action);
      break;
    default:
      msg = ERROR_MESSGAES.INTERNAL_SERVER_ERROR;
  }
  return msg;
};

export const commitBatchErrorMessage = (
  msg: string,
  action: string
): string => {
  let batchErrorMessage = '';
  switch (msg) {
    case customConflictMessage(action):
      batchErrorMessage =
        'One or more beta configs do not contain a draft. Kindly refresh the page';
      break;
    default:
      batchErrorMessage = ERROR_MESSGAES.INTERNAL_SERVER_ERROR;
  }
  return batchErrorMessage;
};

// Using any errors type as 'any' becuse ApolloClient automotically inserts property called
// graphQL errors in th errors array.
// More info - https://www.apollographql.com/docs/react/data/error-handling/#graphql-error-policies
export const handleDraftErrorMessage = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  errors: any,
  action: string
): string => {
  const extensions = errors.graphQLErrors[0]?.extensions;
  const errorCode = extensions?.code;
  let errorMesssage;
  switch (errorCode) {
    // For 401 UNAUTHENTICATED and 403 FORBIDDEN errors, its handled in default error handler
    // and the user will be logged out
    // src/hooks/useDefaultErrorHandler.ts
    case 'RESOURCE_NOT_FOUND': // 404
      errorMesssage = ERROR_MESSGAES.RESOURCE_NOT_FOUND;
      break;
    case 'BAD_USER_INPUT': // 400
      errorMesssage = ERROR_MESSGAES.BAD_USER_INPUT;
      break;
    case 'INTERNAL_SERVER_ERROR': // Any other unhandled error from graphQL
      errorMesssage = handleCustomErrors(extensions.httpStatusCode, action);
      break;
    default:
      errorMesssage = genericErrorMesage(action);
  }
  return errorMesssage;
};
